import { Link } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import OrdersTable from "components/tables/Orders";
import { CSVLink } from "react-csv";
import { useState } from "react";
import { formatISODate, currency } from "utils/helpers";

export default function Orders() {
  const [list, setList] = useState([]);

  const setFormattedList = (data) => {
    const formatted = data.map((value) => {
      return {
        "Order Number": value.number,
        "Order Date": formatISODate(value.date, "DD/MM/YYYY"),
        Module: value.module,
        Total: value.amounts.total ? currency(value.amounts.total) : "-",
        Rounding: value.amounts.rounding
          ? currency(value.amounts.rounding)
          : "-",
        Locations: value.counts?.locations,
        Items: value.counts?.items,
        Status: value.status,
      };
    });

    setList(formatted);
  };

  return (
    <UserLayout title="Orders">
      <HeadSection heading="Orders">
        {list.length > 0 && (
          <CSVLink
            data={list}
            filename={`orders-${new Date().toISOString()}.csv`}
            className="btn btn-primary btn-outline"
          >
            Export CSV
          </CSVLink>
        )}
        <Link to={`/create/order`} className="btn btn-secondary btn-outline">
          Create Order
        </Link>
      </HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <OrdersTable exportList={list} forExport={setFormattedList} />
      </section>
    </UserLayout>
  );
}
