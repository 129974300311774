import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LIST_ORDERS } from "utils/queries";
import { DELETE_ORDER } from "utils/mutations";
import toast from "react-hot-toast";
import clsx from "clsx";
import { LoadingTable } from "layouts/Loading";
import { statusTagColor, currency, formatISODate } from "utils/helpers";

export default function OrdersTable(props) {
  const { exportList, forExport } = props;

  const threeMonthsAgo = new Date(
    new Date().setMonth(new Date().getMonth() - 3)
  )
    .toISOString()
    .split("T")[0];

  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const [orders, setOrders] = useState([]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const [module, setModule] = useState(searchParams.get("module") || "ALL");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState([threeMonthsAgo, tomorrow]);

  const [debouncedNumber] = useDebounce(number, 1000);
  const [debouncedDate] = useDebounce(date, 1000);

  const { loading, refetch, startPolling, stopPolling } = useQuery(
    LIST_ORDERS,
    {
      onCompleted: (data) => {
        if (data?.orders?.success) {
          setOrders(data?.orders?.list);
          setTotal(data?.orders?.total);
          setMaxPage(Math.ceil(data?.orders?.total / limit));

          if (forExport && exportList.length !== data?.orders?.total) {
            getOrdersForExport();
          }
        }
      },
      onError: (error) => {
        toast.error(error.message);
        console.error(error);
      },
      variables: {
        module: !!module && module !== "ALL" ? module : undefined,
        number: !!debouncedNumber ? debouncedNumber : undefined,
        date: debouncedDate,
        limit,
        page,
      },
      fetchPolicy: "network-only",
    }
  );

  const [deleteOrder] = useMutation(DELETE_ORDER, {
    onCompleted: (data) => {
      if (!data?.deleted?.success) toast.error("Order can not be deleted");
      if (data?.deleted?.success) {
        toast.success("Order deleted successfully");
        refetch();
      }
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
    refetchQueries: [{ query: LIST_ORDERS }],
  });

  const [getOrdersForExport] = useLazyQuery(LIST_ORDERS, {
    onCompleted: (data) => {
      if (data?.orders?.success) forExport(data?.orders?.list);
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
    variables: {
      module: !!module && module !== "ALL" ? module : undefined,
      number: !!debouncedNumber ? debouncedNumber : undefined,
      date: debouncedDate,
      limit: total,
      page: 0,
    },
    fetchPolicy: "network-only",
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this order?")) return;
    deleteOrder({ variables: { id } });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  useEffect(() => {
    if (!!module) setSearchParams({ module });
    // esle remove module from searchParams
    if (!module) setSearchParams({ module: undefined });
  }, [module, setSearchParams]);

  useEffect(() => {
    startPolling(15000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <header className="flex flex-col md:flex-row justify-between p-2 gap-4">
        <div className="basis-1/4">
          <input
            className="input input-bordered w-full sm:w-80 bg-base-200 form-control"
            type="search"
            inputMode="search"
            placeholder="Filter by number"
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>
        <div className="basis-3/4 flex flex-row flex-wrap gap-2 items-center md:justify-end">
          {/* filter - from & to (date) */}
          <div className="flex flex-row gap-2 items-center w-full sm:w-fit">
            <div className="flex flex-col gap-1 w-full">
              <input
                className="input input-bordered w-full sm:w-44 bg-base-200"
                type="date"
                max={date[1]}
                value={date[0]}
                onChange={(e) => setDate([e.target.value, date[1]])}
              />
            </div>
            <span>-</span>
            <div className="flex flex-col gap-1 w-full">
              <input
                className="input input-bordered w-full sm:w-44 bg-base-200"
                type="date"
                min={date[0]}
                value={date[1]}
                onChange={(e) => setDate([date[0], e.target.value])}
              />
            </div>
          </div>
          <select
            className="input input-bordered w-full sm:w-44 bg-base-200"
            name="module"
            onChange={(e) => setModule(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="PRODUCE">Produce</option>
            <option value="GROCERY">Grocery</option>
            <option value="FOOD">Food</option>
          </select>
        </div>
      </header>
      <main className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Number</th>
              <th className="text-xs">Date</th>
              <th className="text-xs">Module</th>
              <th className="text-xs text-center">Locations</th>
              <th className="text-xs text-center">Items</th>
              <th className="text-xs text-center">Total</th>
              <th className="text-center text-xs">Status</th>
              <th className="text-xs">
                <span className="pl-3">Action</span>
              </th>
            </tr>
          </thead>
          {loading ? (
            <LoadingTable rows={limit} cols={9} className="h-6" />
          ) : (
            <tbody className="divide-y-2">
              {orders.length > 0 ? (
                orders.map((value, index) => (
                  <tr key={index} className="text-left">
                    <td>{page * limit + index + 1}</td>
                    <th className="min-w-[140px]">{value.number}</th>
                    <th className="min-w-[140px]">
                      {formatISODate(value.date, "DD/MM/YYYY")}
                    </th>
                    <th>{value.module}</th>
                    <td className="text-center">{value.counts?.locations}</td>
                    <td className="text-center">{value.counts?.items}</td>
                    <td className="text-center font-mono">
                      {value.amounts.total
                        ? currency(value.amounts.total)
                        : "-"}
                    </td>
                    <td className="text-center">
                      <span
                        className={clsx(
                          "text-xs py-1 px-2 rounded",
                          `${statusTagColor(value.status)}`
                        )}
                      >
                        {value.status}
                      </span>
                    </td>
                    <td className="space-x-2 min-w-[280px]">
                      <Link
                        className="btn btn-sm btn-ghost"
                        to={`/order/${value.id}/summary`}
                      >
                        View
                      </Link>
                      <Link
                        className={clsx(
                          "btn btn-sm btn-ghost",
                          value.status !== "DRAFT" && "btn-disabled"
                        )}
                        to={`/update/order/${value.id}`}
                      >
                        Update
                      </Link>
                      <button
                        className={clsx(
                          "btn btn-sm btn-ghost",
                          value.status !== "DRAFT" && "btn-disabled"
                        )}
                        onClick={() => handleDelete(value.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No orders found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </main>
      <footer className="flex flex-col justify-between p-2">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full gap-4">
          <div>
            <p>
              Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
              <span className="font-bold">{page * limit + orders?.length}</span>{" "}
              of <span className="font-bold">{total}</span> results
            </p>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-sm"
              disabled={page === 0 || maxPage <= 1}
              onClick={() => prevPage()}
            >
              Prev
            </button>
            <button
              className="btn btn-outline btn-sm"
              disabled={page === maxPage - 1 || maxPage <= 1}
              onClick={() => nextPage()}
            >
              Next
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}
